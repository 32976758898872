// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../node_modules/font-awesome/css/font-awesome.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * After downgrading FontAwesome v5 to v4, there is only\n * \".fa\" class set in the dependency-imported CSS. We need to\n * set \".fas\" and \".far\" classes to the same attributes as \".fa\"\n * here. The other way would be to manually change all \".far\"/\".fas\"\n * occurrences to \".fa\" which implies a bug risk.\n */\n.fas,\n.far {\n  display: inline-block;\n  font: normal normal normal 14px/1 FontAwesome;\n  font-size: inherit;\n  text-rendering: auto;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n", "",{"version":3,"sources":["webpack://./src/common/styles/icons.css"],"names":[],"mappings":"AAEA;;;;;;EAME;AACF;;EAEE,qBAAqB;EACrB,6CAA6C;EAC7C,kBAAkB;EAClB,oBAAoB;EACpB,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["@import '~font-awesome/css/font-awesome.css';\n\n/**\n * After downgrading FontAwesome v5 to v4, there is only\n * \".fa\" class set in the dependency-imported CSS. We need to\n * set \".fas\" and \".far\" classes to the same attributes as \".fa\"\n * here. The other way would be to manually change all \".far\"/\".fas\"\n * occurrences to \".fa\" which implies a bug risk.\n */\n.fas,\n.far {\n  display: inline-block;\n  font: normal normal normal 14px/1 FontAwesome;\n  font-size: inherit;\n  text-rendering: auto;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
