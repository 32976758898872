// Excerpt from the color palette used in MLflow UI, intended to be used in the MLflow experiment runs.
// The color values are copied instead of used from theme directly.
export const RUNS_COLOR_PALETTE = [
  // Secondary colors:
  '#a6630c', // Brown
  '#c83243', // Coral
  '#b45091', // Pink
  '#8a63bf', // Purple
  '#434a93', // Indigo
  '#137dae', // Turquoise
  '#04867d', // Teal
  '#308613', // Lime
  '#facb66', // Lemon

  // Colors list, intensities 700-400:
  '#1f272d', // Grey 700
  '#445461', // Grey 600
  '#5f7281', // Grey 500
  '#8396a5', // Grey 400

  '#93320b', // Yellow 700
  '#be501e', // Yellow 600
  '#de7921', // Yellow 500
  '#f2be88', // Yellow 400

  '#115026', // Green 700
  '#277c43', // Green 600
  '#3caa60', // Green 500
  '#8ddda8', // Green 400

  '#9e102c', // Red 700
  '#c82d4c', // Red 600
  '#e65b77', // Red 500
  '#f792a6', // Red 400

  '#0e538b', // Blue 700
  '#2272b4', // Blue 600
  '#4299e0', // Blue 500
  '#8acaff', // Blue 400
];

export const RUNS_COLOR_PALETTE_400 = [
  '#9a4780',
  '#5e8f46',
  '#8fe5ff',
  '#58914c',
  '#9e4338',
  '#53002e',
  '#253100',
  '#2d2000',
  '#6af3ff',
  '#72e6b9',
  '#9063ac',
  '#a76a32',
  '#00c9f5',
  '#801d4a',
  '#f6f59c',
  '#006e5d',
  '#876b1e',
  '#007395',
  '#80a355',
  '#d2feab',
  '#883872',
  '#99334d',
  '#002300',
  '#fb96c7',
  '#005171',
  '#006ea2',
  '#d47f59',
  '#505a03',
  '#9e4e89',
  '#00d5da',
  '#00421a',
  '#032d6f',
  '#007763',
  '#40000c',
  '#003b7f',
  '#ffcaff',
  '#9e68ae',
  '#8d7bc9',
  '#7bb770',
  '#009aab',
  '#360000',
  '#79e0ac',
  '#50ffff',
  '#555900',
  '#005274',
  '#b1873e',
  '#ff93a5',
  '#b68cd8',
  '#bee896',
  '#ffb87f',
  '#99e8ff',
  '#b54e59',
  '#c89e53',
  '#573c00',
  '#ff9294',
  '#007ca7',
  '#00a8a2',
  '#a0532f',
  '#0da784',
  '#003f69',
  '#9f95e6',
  '#241151',
  '#00c5c1',
  '#ffb6d7',
  '#251a00',
  '#00b6be',
  '#004e8f',
  '#b75787',
  '#e5bd6f',
  '#001a4d',
  '#00999b',
  '#400009',
  '#94432a',
  '#004a1a',
  '#a188d6',
  '#00714f',
  '#c55f5f',
  '#004487',
  '#578942',
  '#c79eed',
  '#e27c70',
  '#baad59',
  '#00295d',
  '#8bb1ff',
  '#ffed99',
  '#009ed1',
  '#d3b564',
  '#00321f',
  '#ff9bba',
  '#00a9d1',
  '#00444b',
  '#00d1de',
  '#9b3b68',
  '#007a5c',
  '#dc8960',
  '#385ba4',
  '#00afdf',
  '#a4da8d',
  '#2a6ab4',
  '#926421',
  '#004b4a',
  '#8b5f1b',
  '#948130',
  '#d794da',
  '#00e7ff',
  '#82ffe6',
  '#002900',
  '#2b6220',
  '#96314d',
  '#84d7ff',
  '#558a44',
  '#50084b',
  '#0082a3',
  '#7f6617',
  '#e896d5',
  '#995c27',
  '#7f4b10',
  '#b771b4',
  '#92313b',
  '#002100',
  '#00dbda',
  '#00a7a5',
  '#5a5200',
  '#004f92',
  '#00a8af',
  '#00c1b9',
  '#ffa9a3',
  '#55ffff',
  '#0092b0',
  '#007147',
  '#983350',
  '#6a3173',
  '#dbc7ff',
  '#ffd0f5',
  '#004536',
  '#ffb7a4',
  '#6a0f4a',
  '#51ffff',
  '#007c73',
  '#ffa785',
  '#658639',
  '#00235f',
  '#703d01',
  '#c85f6a',
  '#0084c3',
  '#bdd682',
  '#da6f8e',
  '#8f3132',
  '#d58659',
  '#002f00',
  '#211f61',
  '#78be7b',
  '#7ec27d',
  '#007a52',
  '#fabb78',
  '#005081',
  '#540035',
  '#0094cb',
  '#008487',
  '#008fd3',
  '#b56cae',
  '#82a252',
  '#006739',
  '#ffd4ff',
  '#398549',
  '#d3b3ff',
  '#55002e',
  '#0084c1',
  '#c0994d',
  '#de7770',
  '#614800',
  '#004f78',
  '#720834',
  '#96a04c',
  '#621105',
  '#fdf39a',
  '#0089c5',
  '#592061',
  '#796fbc',
  '#006479',
  '#50ffff',
  '#ff9788',
  '#005248',
  '#005f41',
  '#57ac72',
  '#1e3300',
  '#6c3200',
  '#a9b0ff',
  '#613300',
  '#68b7ff',
  '#455600',
  '#2a9be2',
  '#553200',
  '#00b4d4',
  '#998a38',
  '#ffd59c',
  '#004224',
  '#005446',
  '#0097cb',
  '#003273',
  '#540016',
  '#e2ed95',
  '#00634f',
  '#f0838a',
  '#bfa856',
  '#2e2100',
  '#de90d2',
  '#00c9c8',
  '#9ab0ff',
  '#6c0136',
  '#003128',
  '#d8bb69',
  '#c46951',
  '#d29857',
  '#005a8b',
  '#ce6587',
  '#3f1052',
  '#6b2d01',
  '#005397',
  '#bd9148',
  '#963662',
  '#00755c',
  '#c2c6ff',
  '#631d00',
  '#00294b',
  '#6d5ba6',
  '#557c31',
  '#0085ab',
  '#36cbb0',
  '#21d5c6',
  '#8d2c34',
  '#00643b',
  '#002131',
  '#f3a2e4',
  '#e493d4',
  '#a85d35',
  '#7f2b1e',
  '#001c5c',
  '#a1bbff',
  '#7a1e21',
  '#7b903f',
  '#00abdd',
  '#686712',
  '#0f956c',
  '#b5aeff',
  '#00abde',
  '#ae593f',
  '#ffd0da',
  '#9a5596',
  '#c15968',
  '#715504',
  '#9c3963',
  '#ca7f4f',
  '#3d2d72',
  '#739dec',
  '#b25646',
  '#cbb25f',
  '#003e65',
  '#640035',
  '#fea67c',
  '#003200',
  '#007381',
  '#ffa3dd',
  '#00467e',
  '#9d9bed',
  '#63f0ff',
  '#590a4c',
  '#dc7374',
  '#ec8f6f',
  '#002000',
  '#004d49',
  '#a3ffcb',
  '#00578d',
  '#003769',
  '#543400',
  '#ffa1d8',
  '#9ff9ff',
  '#ffe3a0',
  '#663c00',
  '#9b3d6d',
  '#ffb4de',
  '#0073b9',
  '#004186',
  '#805310',
  '#81feff',
  '#00c4db',
  '#211200',
  '#5a448b',
  '#7f8ddd',
  '#00838c',
  '#9d582b',
  '#b95167',
  '#008192',
  '#d1755c',
  '#00c3b6',
  '#c3934c',
  '#006a47',
  '#2c570f',
  '#479e66',
  '#0083a0',
  '#8b621c',
  '#3e0000',
  '#293b00',
  '#6096e3',
  '#22003b',
  '#cfffad',
  '#c3d3ff',
  '#cf6576',
  '#5c7a2d',
  '#008f71',
  '#ca6268',
  '#7b4285',
  '#b66442',
  '#4e5f0c',
  '#ae6aad',
  '#00222f',
  '#6161ad',
  '#c4a1f0',
  '#0070ae',
  '#3b5705',
  '#003e19',
  '#792056',
  '#005a8a',
  '#783b0b',
  '#841f3d',
  '#9ab25f',
  '#3f6419',
  '#cf705d',
  '#003b00',
  '#ab6137',
  '#0c3d00',
  '#00245a',
  '#832b62',
  '#687fcd',
  '#998232',
  '#0e6c36',
  '#ada34f',
  '#005e28',
  '#00bfe4',
  '#22b594',
  '#b5d27e',
  '#00609d',
  '#0086ae',
  '#2682cb',
  '#ffd0dd',
  '#c7f1ff',
  '#7d4e94',
  '#00568c',
  '#e8a2e9',
  '#a56e30',
  '#b28cd9',
  '#cda8f7',
  '#c094e1',
  '#06b89f',
  '#00afe0',
  '#666d19',
  '#61630d',
  '#0d1800',
  '#009bdf',
  '#8b366e',
  '#ee8c74',
  '#ede78e',
  '#00817a',
  '#002159',
  '#3c8a4e',
  '#b0588f',
  '#3362ab',
  '#002262',
  '#008a67',
  '#ee99d7',
  '#ffd6b0',
  '#7a1f54',
  '#007c6d',
  '#a6b4ff',
  '#202100',
  '#734a91',
  '#00619a',
  '#945922',
  '#69a661',
  '#002b68',
  '#005390',
  '#f5f69c',
  '#006877',
  '#2a7cc5',
  '#105f25',
  '#8a302b',
  '#008e9a',
  '#a69dee',
  '#529250',
  '#007891',
  '#ffd2cc',
  '#00394c',
  '#2c77c0',
  '#66001e',
  '#913531',
  '#b55075',
  '#d9a2ee',
  '#5f2b6e',
  '#19814f',
  '#007366',
];
